import React, { useEffect, useState } from 'react';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import Activity from './Activity';
import Dashboard from './Dashboard';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import AntSwitch from '../../../components/AntSwitch';
import ApiResourceSelect from '../../../components/ApiResourceSelect';
import UserService from '../../../services/UserService';
import api from '../../../services/Api';
import { ListSolicitationProcessForm } from '../SolicitationProcessForm/List-SolicitationProcessForm';

export type SolicitationSituations = {
    status: string,
    quantity: number,
}

export type MonthlySolicitations = {
    month: string,
    quantity: number,
}

export type AllSolicitationSituations = {
    status: string,
    quantity: number,
    total: number,
}

export type DeadlineSolicitations = {
    quantity: number,
    situation: string,
    total: number,
}

export type OpenSolicitationPerProcess = {
    quantity: number,
    processName: string,
}

export function ListSolicitation() {
    const [typeShowDashboard, setTypeShowDashboard] = useState<string>("month");
    const [monthDashboard, setMonthDashboard] = useState(new Date().getMonth() + 1);
    const [yearsAvailable, setYearsAvailable] = useState<Number[]>([]);
    const [yearDashboard, setYearDashboard] = useState(new Date().getFullYear());
    const [user, setUser] = useState<string>("");
    const [dataCompletedSolicitations, setDataCompletedSolicitations] = useState<MonthlySolicitations[]>([]);
    const [dataIncompletedSolicitations, setDataIncompletedSolicitations] = useState<MonthlySolicitations[]>([]);
    const [solicitationsStatus, setSolicitationsStatus] = useState<SolicitationSituations[]>([]);
    const [dataAllSolicitationStatus, setDataAllSolicitationStatus] = useState<AllSolicitationSituations[]>([]);
    const [dataDeadlinesSolicitations, setDataDeadlinesSolicitations] = useState<DeadlineSolicitations[]>([]);
    const [dataOpenSolicitationsPerProcess, setDataOpenSolicitationsPerProcess] = useState<OpenSolicitationPerProcess[]>([]);
    const [activeTab, setActiveTab] = useState<string>("activities");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getYearsAvailable = async () => {
        try {
            const response = await api.get('/bpm/process-solicitation/getYears')
            setYearsAvailable(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getSolicitationsStatus = async () => {
        setIsLoading(true);
        try {
            const monthWithPad = String(monthDashboard).padStart(2, "0");
            const response = await api.get(`/bpm/process-solicitation/getStatus/${monthWithPad}/${yearDashboard}`);
            setSolicitationsStatus(response.data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const getMonthlyCompletedSolicitations = async () => {
        setIsLoading(true);
        try {
            const result = await api.get(`/bpm/process-solicitation/getMonthly/${yearDashboard}/completed`);
            setDataCompletedSolicitations(result.data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const getMonthlyIncompletedSolicitations = async () => {
        setIsLoading(true);
        try {
            const result = await api.get(`/bpm/process-solicitation/getMonthly/${yearDashboard}/active`);
            setDataIncompletedSolicitations(result.data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }
    
    const getAllSolicitationsStatus = async () => {
        setIsLoading(true);
        try {
            const monthWithPad = String(monthDashboard).padStart(2, "0");
            const result = await api.get(`/bpm/process-solicitation/getAllStatus/${monthWithPad}/${yearDashboard}`);
            setDataAllSolicitationStatus(result.data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const getSolicitationsDeadline = async () => {
        try {
            const monthWithPad = String(monthDashboard).padStart(2, "0");
            const result = await api.get(`/bpm/process-solicitation/getDeadlines/${monthWithPad}/${yearDashboard}`);
            setDataDeadlinesSolicitations(result.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getOpenSolicitationsPerProcess = async () => {
        try {
            const monthWithPad = String(monthDashboard).padStart(2, "0");
            const result = await api.get(`/bpm/process-solicitation/getOpenSolicitationsPerProcess/${monthWithPad}/${yearDashboard}`);
            setDataOpenSolicitationsPerProcess(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getYearsAvailable();
        getSolicitationsStatus();
        getMonthlyCompletedSolicitations();
        getMonthlyIncompletedSolicitations();
        getAllSolicitationsStatus();
        getSolicitationsDeadline();
        getOpenSolicitationsPerProcess();
    }, []);

    useEffect(() => {
        getMonthlyCompletedSolicitations();
        getMonthlyIncompletedSolicitations();
        getAllSolicitationsStatus();
        getSolicitationsDeadline();
        getSolicitationsStatus();
        getOpenSolicitationsPerProcess();
    }, [yearDashboard, monthDashboard]);

    return (
        <>
            <div className={"mb-3 " + (activeTab !== 'dashboard' ? 'd-none' : '')}>
                <div className="card card-custom gutter-b">
                    <div className="card-body d-flex flex-row align-items-center justify-content-between pt-3 pb-3 pr-5 pl-5">
                        <div className="d-flex flex-row align-items-center">
                            <b className="mr-5"><i className="flaticon2-calendar"></i>&nbsp; Data da Dashboard</b>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Mês</Grid>
                                    <Grid item>
                                        <AntSwitch
                                            color='default'
                                            checked={typeShowDashboard == "year" ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                e.target.checked ? setTypeShowDashboard("year") : setTypeShowDashboard("month");
                                                setMonthDashboard(new Date().getMonth() + 1);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>Ano</Grid>
                                </Grid>
                            </Typography>
                        </div>

                        <div className="row col-lg-6 justify-content-end">
                            {
                            typeShowDashboard == "month" 
                            ?
                                <div className="col-lg-3">
                                    <TextField
                                        size="small"
                                        select
                                        label='Mês'
                                        margin='normal'
                                        variant='outlined'
                                        value={monthDashboard}
                                        onChange={(e: any) => setMonthDashboard(Number(e.target.value))}
                                    >
                                        <MenuItem key='0' value='1'>
                                            Janeiro
                                        </MenuItem>

                                        <MenuItem key='1' value='2'>
                                            Fevereiro
                                        </MenuItem>

                                        <MenuItem key='2' value='3'>
                                            Março
                                        </MenuItem>

                                        <MenuItem key='3' value='4'>
                                            Abril
                                        </MenuItem>

                                        <MenuItem key='4' value='5'>
                                            Maio
                                        </MenuItem>

                                        <MenuItem key='5' value='6'>
                                            Junho
                                        </MenuItem>

                                        <MenuItem key='6' value='7'>
                                            Julho
                                        </MenuItem>

                                        <MenuItem key='7' value='8'>
                                            Agosto
                                        </MenuItem>

                                        <MenuItem key='8' value='9'>
                                            Setembro
                                        </MenuItem>

                                        <MenuItem key='9' value='10'>
                                            Outubro
                                        </MenuItem>

                                        <MenuItem key='10' value='11'>
                                            Novembro
                                        </MenuItem>

                                        <MenuItem key='11' value='12'>
                                            Dezembro
                                        </MenuItem>
                                    </TextField>
                                </div>
                            : <></>
                            }

                            <div className="col-lg-3">
                                <TextField
                                    size="small"
                                    select
                                    label='Ano'
                                    margin='normal'
                                    variant='outlined'
                                    value={yearDashboard}
                                    onChange={(e: any) => setYearDashboard(Number(e.target.value))}
                                >
                                    {
                                        yearsAvailable.map((year: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={year}>
                                                {year}
                                            </MenuItem>
                                        )
                                        })
                                    }
                                </TextField>
                            </div>

                            {/* <div className="col-lg-6">
                                <ApiResourceSelect
                                    label="Usuário"
                                    noOptionsText="Nenhum usuário encontrado"
                                    getOptionLabel={(option: any) => UserService.getFullName(option, '')}
                                    value={user}
                                    onSelect={(option) => setUser(String(option?.id ?? 'all'))}
                                    apiSearchHandler={(typedText) => UserService.getAllUsersFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!user || user === 'all') return null;
                                        return loadedOptions.find((option) => option.id === Number(user)) ?? UserService.getUserById(user)
                                    }}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-body pt-4">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(tab: string) => setActiveTab(tab)}
                    className="mb-3"
                    id='solicitation-tabs'
                >
                    <Tab
                        eventKey='activities'
                        title="Atividades"
                        className='py-5'
                    >
                        <Activity/>
                    </Tab>
                    <Tab
                        eventKey='dashboard'
                        title="Dashboards"
                        className='py-5'
                    >
                        {
                            isLoading
                            ?
                                <div className="w-100 my-6 d-flex justify-content-center">
                                    <Spinner
                                        as="span"
                                        variant="primary"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </div>
                            :
                                <Dashboard
                                    solicitationsStatus={solicitationsStatus}
                                    dataCompletedSolicitations={dataCompletedSolicitations}
                                    dataIncompletedSolicitations={dataIncompletedSolicitations}
                                    dataAllSolicitationStatus={dataAllSolicitationStatus}
                                    dataDeadlinesSolicitations={dataDeadlinesSolicitations}
                                    dataOpenSolicitationsPerProcess={dataOpenSolicitationsPerProcess}
                                />
                        }
                    </Tab>
                    <Tab
                        eventKey='process'
                        title="Processos"
                        className='py-5'
                    >
                        <ListSolicitationProcessForm />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Search } from '../../../components/Search';
import { ListWithModalChangeSituation, LoadDataParams } from '../../../components/ListWithModalChangeSituation';
import { BodyDataBaseProps, HeadDataBaseProps } from '../../../components/ListWithModalChangeSituation';
import api from '../../../services/Api';
import useBackendLoad from '../../../hooks/backendReload';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getDate } from '../../../utils/dateTimeHelper';

type Filters = {
	searchQuery: string;
}

const headData: HeadDataBaseProps[] = [
	{ reference: "id", value: "Nº" },
	{ reference: "name", value: "Nome" },
	{ reference: "description", value: "Descrição" },
	{ reference: "createdAt", value: "Data de criação" },
	{ reference: "updatedAt", value: "Data de atualização" },
]

export function ListSolicitationProcessForm() {
	const { user } = useSelector((state: any) => state.auth);

	const { push: pushHistory, location: { pathname } } = useHistory();

	const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
	const [processForm, setProcessForm] = useState<any[]>([]);
	const [processFormFiltered, setProcessFormFiltered] = useState<any[]>([]);
	const [countTotalProcessForm, setCountTotalProcessForm] = useState<number>(0);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const filtersRef = useRef<Filters | null>(null);

	const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

	const loadData = useCallback(async ({
		rowsPerPage,
		currentPage,
	}: LoadDataParams) => {
		const { data } = await api.get<{rows: any[], count: number}>("bpm/solicitacao-processo-formulario/displayAll", {
			params: {
					skip: rowsPerPage * currentPage,
					take: rowsPerPage,
					filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
			}
		});

		const {rows, count} = data;

		setProcessForm(rows);
		setProcessFormFiltered(rows);
		setCountTotalProcessForm(count);
	}, []);

	const fetchProcessForm = async () => {
		try {
			const results = await api.get("/bpm/solicitacao-processo-formulario/displayAll");
			setProcessForm(results.data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchProcessForm();
	}, []);

	useLayoutEffect(() => {
		const list: BodyDataBaseProps[][] = [];
		const aux = processFormFiltered;

		aux.forEach((processFormAux) => {
			const data: BodyDataBaseProps[] = [
				{ for: "id", value: String(processFormAux.id), id: true },
				{ for: "name", value: processFormAux.name },
				{ for: "description", value: processFormAux.description },
				{ for: "createdAt", value: getDate({ initialDate: new Date(processFormAux.createdAt) }).dateStr },
				{ for: "updatedAt", value: getDate({ initialDate: new Date(processFormAux.updatedAt) }).dateStr },
			]

			list.push(data);
		})

		setBodyData(list);
	}, [processFormFiltered]);

	const handleClickSearch = useCallback(() => {
		filtersRef.current = {
			searchQuery
		};

		reloadData();
	}, [processForm, searchQuery]);

	const handleClickAdd = useCallback(() => {
		pushHistory(`/bpm/solicitacao-processo-formulario/0`);
	}, []);

	const handleClickEdit = useCallback((id: string) => {
		pushHistory(`/bpm/solicitacao-processo-formulario/${id}`);
	}, []);

    return (
        <div className="card card-body pt-4">
					<div className="row d-flex align-items-center">
						<div className="col-lg-9 mt-3">
							{
								user.isAccountant == 'n'
								?
									<Button
										type="button"
										variant="success"
										className="mr-2"
										onClick={handleClickAdd}
									>
										Novo processo
									</Button>
								: <></>
							}
						</div>
						<div className="col-lg-3 mt-3">
							<Search
								query={searchQuery}
								setQuery={setSearchQuery}
								onClickSearch={handleClickSearch}
							/>
						</div>
					</div>
					<div className="mt-3">
						<ListWithModalChangeSituation
							headData={headData}
							bodyData={bodyData}
							onEdit={handleClickEdit}
							sortable={true}
							loadData={loadData}
							totalCount={countTotalProcessForm}
							triggerLoad={triggerLoad}
							setTriggerLoad={setTriggerLoad}
						/>
					</div>
        </div>
    );
}
import { Checkbox, Grid, IconButton, MenuItem, Radio, TextField, Tooltip } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Field, ModalField } from '../pages/Bpm/ModelForm/ModalField';
import { Table, DataColumn, ModalTable } from '../pages/Bpm/ModelForm/ModalTable';
import api from '../services/Api';
import { useHistory, useParams } from 'react-router';
import { useStyles } from '../hooks/styles';
import ModalError from '../components/ModalError';
import ModalSuccess from '../components/ModalSuccess';
import { Search } from './Search';

const formatType: {[key: string]: string} = {
    "Texto": "Texto",
    "Inteiro": "Número inteiro",
    "Decimal": "Número decimal",
    "OPCOES": "Opções",
    "Mascara": "Máscara",
    "Data": "Data",
    "Anexo": "Anexo",
    "Caixa_verificacao": "Caixa de verificação",
}

type bpmnModalForm = {
    groups: any,
    setGroups: (val: any) => void,
    processId: string,
    formId: number,
    removeGroups?: number[];
    setRemoveGroups?: React.Dispatch<React.SetStateAction<number[]>>;
    removeFields?: number[];
    setRemoveFields?: React.Dispatch<React.SetStateAction<number[]>>;
    removeTables?: number[];
    setRemoveTables?: React.Dispatch<React.SetStateAction<number[]>>;
}

type savedInputField = {
    id: number | string,
    textLabel: string,
    type: string,
    isRequired: string,
    mask: string,
}

const BpmnModalForm: React.FC<bpmnModalForm> = ({
    groups,
    setGroups,
    processId,
    formId,
    removeGroups,
    setRemoveGroups,
    removeFields,
    setRemoveFields,
    removeTables,
    setRemoveTables
}) => {
    const { push: pushHistory, location: { pathname } } = useHistory();
    const { register, watch, handleSubmit } = useForm();

    const [searchSavedInput, setSearchSavedInput] = useState<string>("");
    
    const [name, setName] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [nameInvalid, setNameInvalid] = useState(false);
    const [groupsInvalid, setGroupsInvalid] = useState(false);

    const [showModalField, setShowModalField] = useState<boolean>(false);
    const [showModalTable, setShowModalTable] = useState<boolean>(false);
    const [toEditField, setToEditField] = useState<boolean>(false);
    const [toEditTable, setToEditTable] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    
    const [groupIndex, setGroupIndex] = useState<number>(0);
    const [tableIndex, setTableIndex] = useState<number>(0);

    const [fieldCounter, setFieldCounter] = useState<number>(1);
    const [idField, setIdField] = useState<number>(1);
    const [nameField, setNameField] = useState<string>("");
    const [typeField, setTypeField] = useState<string>("Texto");
    const [sizeField, setSizeField] = useState<number>(50);
    const [rowsField, setRowsField] = useState<number>(1);
    const [colunsField, setColunsField] = useState<number>(3);
    const [requiredField, setRequiredField] = useState<string>("n");
    const [maskField, setMaskField] = useState<string>("");
    const [optionField, setOptionField] = useState<string[]>([""]);
    const [decimalField, setDecimalField] = useState<number>(1);
    const [integerDecimalField, setIntegerDecimalField] = useState<number>(1);
    const [extensionField, setExtensionField] = useState<any>([]);
    const [otherExtensionField, setOtherExtensionField] = useState<string>("");
    const [savedIdField, setSavedIdField] = useState<number>(0);
    const [isSavedField, setIsSavedField] = useState<boolean>(false);
    const extensionList = [
        '.jpg', '.jpeg', '.png', '.pdf',
    ];

    const [isLoadingInputName, setIsLoadingInputName] = useState<boolean>(false);

    const [savedInputsFields, setSavedInputsFields] = useState<savedInputField[]>([]);
    const [filteredSavedInputsFields, setFilteredSavedInputsFields] = useState<savedInputField[]>([]);
    const [selectedSavedInput, setSelectedSavedInput] = useState<string>("0");

    const [isExtensionChecked, setIsExtensionChecked] = useState<boolean>(false);
    const [isSavedChecked, setIsSavedChecked] = useState<boolean>(true);

    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorModalText, setErrorModalText] = useState<string>("");
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [alertModalText, setAlertModalText] = useState<string>("");
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [savedFieldsModal, setSavedFieldsModal] = useState<boolean>(false);

    const [idTable, setIdTable] = useState<number>(1);
    const [nameTable, setNameTable] = useState<string>("");
    const [dataColumnsTable, setDataColumnsTable] = useState<DataColumn[]>([{
        id: 0,
        name: "",
        type: "",
        size: 50,
        rows: 1,
        columns: 3,
        mask: "",
        option: [""],
        decimal: 1,
        integerDecimal: 1,
        extensions: [],
        otherExtension: "",
        required: false,
        savedId: 0,
    }]);
    const [columnsTable, setColumnsTable] = useState<number>(12);
    const [rowsTable, setRowsTable] = useState<number>(1);
    const classes = useStyles();

    const getSavedInputs = async () => {
        try {
            let fieldsSelect = await api.get("/bpm/solicitacao-processo-formulario/getSavedInputs");
            setSavedInputsFields(fieldsSelect.data);
            setFilteredSavedInputsFields(fieldsSelect.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getSavedInputName = async () => {
        setIsLoadingInputName(true);
        try {
            let savedInputName = await api.get(`/bpm/solicitacao-processo-formulario/getSavedInputName/${nameField}`);
            if (savedInputName.data) {
                setAlertModal(true);
                setAlertModalText("Esse campo já está salvo, deseja salvar ele mesmo assim?")
                
                return;
            }

            addNewField(groupIndex);
        } catch (error) {
            console.log(error);
        }
        setIsLoadingInputName(false);
    }

    useEffect(() => {
        getSavedInputs();
    }, []);

    const addNewField = useCallback((index: number, savedField: boolean = false) => {
        let groupArr = groups;
        groupArr.group[index].inputs.push({
            id: fieldCounter,
            name: nameField,
            type: typeField,
            size: sizeField,
            rows: rowsField,
            columns: colunsField,
            mask: maskField,
            option: optionField,
            decimal: decimalField,
            integerDecimal: integerDecimalField,
            extensions: extensionField,
            otherExtension: otherExtensionField,
            required: requiredField == "y" ? true : false,
            savedId: 0,
            saveField: isSavedChecked,
            savedField,
        });
        setGroups(groupArr);
        let count = fieldCounter + 1;
        setFieldCounter(count);
        setNameField("");
        setTypeField("Texto");
        setSizeField(50);
        setRowsField(1);
        setColunsField(3);
        setRequiredField("n");
        setMaskField("");
        setOptionField([""]);
        setDecimalField(1);
        setIntegerDecimalField(1);
        setExtensionField([]);
        setOtherExtensionField("");
        setShowModalField(false);
        setToEditField(false);
        setIsSavedChecked(false);
        setAlertModal(false);
        setIsLoadingInputName(false);
    }, [
        nameField,
        typeField,
        sizeField,
        rowsField,
        colunsField,
        requiredField,
        maskField,
        decimalField,
        integerDecimalField,
        optionField,
        extensionField,
        otherExtensionField,
        fieldCounter,
        isSavedChecked
    ]);

    const addField = (index: number) => {
        if (!nameField) {
            setErrorModal(true);
            setErrorModalText("Preencha o nome do campo!");
            return;
        }

        addNewField(index);
    };

    const addSavedField = () => {
        if (selectedSavedInput === "0") {
            setErrorModal(true);
            setErrorModalText("Selecione um campo salvo!");
            return;
        }

        addNewField(groupIndex, true);
        setSavedFieldsModal(false);
    }

    const addTable = useCallback((index: number) => {
        if (!nameTable) {
            setErrorModal(true);
            setErrorModalText("Preencha o nome da tabela!");
            return;
        }

        var groupArr = groups.group;
        groupArr[index].tables.push({
            id: groupArr[index].tables.length + 1,
            name: nameTable,
            dataColumns: dataColumnsTable,
            columns: columnsTable,
            rows: rowsTable,
            savedId: 0,
        });

        setNameTable("");
        setDataColumnsTable([{
            id: 0,
            name: "",
            type: "",
            size: 50,
            rows: 1,
            columns: 3,
            mask: "",
            option: [""],
            decimal: 1,
            integerDecimal: 1,
            extensions: [],
            otherExtension: "",
            required: false,
            savedId: 0,
        }]);
        setColumnsTable(12);
        setRowsTable(1);
        setShowModalTable(false);
    }, [nameTable, dataColumnsTable, columnsTable, rowsTable]);

    const removeField = useCallback((indexGroup: number, idFieldOrTable: number, field?: any) => {
        var groupArr = groups.group;

        groupArr[indexGroup].inputs = groupArr[indexGroup].inputs.filter((input: any) => {return input.id !== field.id});
        deleteDataColumn(field.savedId);

        setGroups({ group: groupArr});
        setSelectedSavedInput("0");
        setNameField("");
        setTypeField("Texto");
        setSizeField(50);
        setRowsField(1);
        setColunsField(3);
        setRequiredField("n");
        setMaskField("");
        setOptionField([""]);
        setDecimalField(1);
        setIntegerDecimalField(1);
        setExtensionField([]);
        setOtherExtensionField("");
        setShowModalField(false);
        setToEditField(false);
    }, [
        groups,
        nameField,
        typeField,
        sizeField,
        rowsField,
        colunsField,
        requiredField,
        maskField,
        decimalField,
        integerDecimalField,
        optionField,
        extensionField,
        otherExtensionField,
        selectedSavedInput
    ]);

    const removeTable = useCallback((indexGroup: number, idTable: number, table?: any) => {
        var groupArr = groups.group;
        groupArr[indexGroup].tables = groupArr[indexGroup].tables.filter((t: any) => {return t.id !== table.id});
        deleteTable(table.savedId);

        setGroups({ group: groupArr});
        setNameTable("");
        setDataColumnsTable([{
            id: 0,
            name: "",
            type: "",
            size: 50,
            rows: 1,
            columns: 3,
            mask: "",
            option: [""],
            decimal: 1,
            integerDecimal: 1,
            extensions: [],
            otherExtension: "",
            required: false,
            savedId: 0,
        }]);
        setColumnsTable(12);
        setRowsTable(1);
        setShowModalTable(false);
        setToEditTable(false);
    }, [nameTable, dataColumnsTable, columnsTable, rowsTable]);

    const handleDecimalFieldChange = (e: ChangeEvent<HTMLInputElement> | any) => {
        if (Number(e.target.value) < 0) {
            setDecimalField(1);
            return;
        }

        if (Number(e.target.value) > 5) {
            setDecimalField(5);
            return;
        }

        setDecimalField(Number(e.target.value));
    }

    const deleteDataColumn = (savedId: number) => {
        if (removeFields && setRemoveFields) {
            let rem = removeFields;
            rem.push(savedId);
            setRemoveFields(rem);
        }
    };

    const deleteTable = (savedId: number) => {
        if (removeTables && setRemoveTables) {
            let rem = removeTables;
            rem.push(savedId);
            setRemoveTables(rem);
        }
    }

    const editField = useCallback((field: Field) => {
        let required = field.required ? "y" : "n";
        let newField = {...field, required: required}; // Cria uma nova cópia do objeto field

        setIdField(newField.id);
        setNameField(newField.name);
        setTypeField(newField.type);
        setSizeField(newField.size);
        setRowsField(newField.rows);
        setColunsField(newField.columns);
        setRequiredField(required);
        setMaskField(newField.mask);
        setOptionField(newField.option);
        setDecimalField(newField.decimal);
        setIntegerDecimalField(newField.integerDecimal);
        setExtensionField(newField.extensions);
        setOtherExtensionField(newField.otherExtension);
        setIsSavedChecked(newField.saveField);
        setSavedIdField(newField.savedId);
        setIsSavedField(newField.savedField);
        setToEditField(true);
        setShowModalField(true);
    }, []);

    const saveEditField = useCallback((groupIndex: number, fieldIndex: number) => {
        const newGroups = [...groups.group];
        let field = newGroups[groupIndex].inputs.find((input: any) => input.id === fieldIndex);
        field!.name = nameField;
        field!.type = typeField;
        field!.size = sizeField;
        field!.rows = rowsField;
        field!.columns = colunsField;
        field!.required = requiredField === "y" ? true : false;
        field!.mask = maskField;
        field!.option = optionField;
        field!.decimal = decimalField;
        field!.integerDecimal = integerDecimalField;
        field!.extensions = extensionField;
        field!.otherExtension = otherExtensionField;
        field!.saveField = false;

        setShowModalField(false);
        setToEditField(false);
        setGroups({group: newGroups});
    }, [
        nameField,
        typeField,
        sizeField,
        rowsField,
        colunsField,
        requiredField,
        maskField,
        optionField,
        decimalField,
        integerDecimalField,
        extensionField,
        otherExtensionField,
    ]);

    const editTable = useCallback((table: Table) => {
        setIdTable(table.id);
        setNameTable(table.name);
        setDataColumnsTable(table.dataColumns);
        setColumnsTable(table.columns);
        setRowsTable(table.rows);
        setToEditTable(true);
        setShowModalTable(true);
    }, [idTable, nameTable, dataColumnsTable, columnsTable, rowsTable, toEditTable, showModalTable]);

    const saveEditTable = useCallback((groupIndex: number, tableindex: number) => {
        const newGroups = [...groups.group];
        let table = newGroups[groupIndex].tables.find((table: any) => table.id === tableindex);
        table!.name = nameTable;
        table!.dataColumns = dataColumnsTable;
        table!.columns = columnsTable;
        table!.rows = rowsTable;
        setShowModalTable(false);
        setToEditTable(false);
    }, [nameTable, dataColumnsTable, columnsTable, rowsTable]);

    const selectSavedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedSavedInput(e.target.value);

        const savedInput = savedInputsFields.find((savedInputField) => savedInputField.id == e.target.value);

        if (savedInput) {
            setNameField(savedInput.textLabel);
            setTypeField(savedInput.type);
            setMaskField(savedInput.mask);
        }
    }

    const handleSearchSavedInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchSavedInput(e.target.value);

        const filteredSavedInputs = savedInputsFields.filter((savedInput) => 
            savedInput.textLabel.toLowerCase().includes(e.target.value.toLowerCase())
        )

        setFilteredSavedInputsFields(filteredSavedInputs);
    }
    
    return (
        <>
            <ModalSuccess
                msgModal={`Formulário ${formId ? "atualizado" : "criado"} com sucesso!`}
                showModal={successModal}
                setShowModal={setSuccessModal}
                redirect='/bpm/modelagem-de-formulario'
            />

            <Modal show={errorModal} onHide={() => setErrorModal(false)}>
                <Modal.Header className="d-flex align-items-center">
                    <Modal.Title>
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{errorModalText}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setErrorModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={alertModal} onHide={() => setAlertModal(false)}>
                <Modal.Header className="d-flex align-items-center">
                    <Modal.Title>
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{alertModalText}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setAlertModal(false); setIsLoadingInputName(false)}}>Cancelar</Button>
                    <Button variant="primary" onClick={() => addNewField(groupIndex)}>Adicionar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={savedFieldsModal} onHide={() => setSavedFieldsModal(false)} size="lg">
                <Modal.Header>
                    <Modal.Title className="d-flex align-items-center">
                        Campos salvos
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid container justify="flex-end">
                        <Grid item xs={4} className="mb-3">
                            <TextField
                                size="small"
                                label="Buscar campo salvo"
                                margin="normal"
                                variant="outlined"
                                value={searchSavedInput}
                                onChange={(e) => handleSearchSavedInput(e)}
                            />
                        </Grid>
                    </Grid>
                    {
                        filteredSavedInputsFields.length
                        ?
                            <>
                                <Grid container>
                                    <Grid item xs={1} className="p-3">
                                    </Grid>
                                    <Grid item xs={3} className="p-3">
                                        <h6>Nome</h6>
                                    </Grid>
                                    <Grid item xs={2} className="p-3">
                                        <h6>Tipo</h6>
                                    </Grid>
                                    <Grid item xs={3} className="p-3">
                                        <h6>Valor padrão</h6>
                                    </Grid>
                                    <Grid item xs={3} className="p-3">
                                        <h6>Obrigatório</h6>
                                    </Grid>
                                </Grid>

                                <div className="d-flex flex-column" style={{ rowGap: "0.5rem" }}>
                                    {
                                        filteredSavedInputsFields.map((savedInputField: savedInputField) => (
                                            <Grid key={savedInputField.id} container className="card flex-row" alignItems="center">
                                                <Grid item xs={1} className="p-3">
                                                    <Radio
                                                        checked={selectedSavedInput == savedInputField.id}
                                                        onChange={(e) => selectSavedInput(e)}
                                                        value={savedInputField.id}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="p-3">
                                                    {savedInputField.textLabel}
                                                </Grid>
                                                <Grid item xs={2} className="p-3">
                                                    {formatType[savedInputField.type]}
                                                </Grid>
                                                <Grid item xs={3} className="p-3">
                                                    {savedInputField.mask}
                                                </Grid>
                                                <Grid item xs={3} className="p-3">
                                                    {savedInputField.isRequired === 'y' ? "Sim" : "Não"}
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </div>
                            </>
                        :
                            <Grid item xs={12}>
                                <h5 className="text-center">Não há campos salvos!</h5>
                            </Grid>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSavedFieldsModal(false)}>Fechar</Button>
                    <Button variant="primary" onClick={addSavedField}>Adicionar</Button>
                </Modal.Footer>
            </Modal>

            <ModalField
                addField={addField}
                saveEditField={saveEditField}
                removeField={removeField}
                groupIndex={groupIndex}
                showModalField={showModalField}
                setShowModalField={setShowModalField}
                toEditField={toEditField}
                idField={idField}
                nameField={nameField}
                setNameField={setNameField}
                typeField={typeField}
                setTypeField={setTypeField}
                sizeField={sizeField}
                setSizeField={setSizeField}
                rowsField={rowsField}
                setRowsField={setRowsField}
                colunsField={colunsField}
                setColunsField={setColunsField}
                requiredField={requiredField}
                setRequiredField={setRequiredField}
                modalTitle={modalTitle}
                maskField={maskField}
                setMaskField={setMaskField}
                optionsField={optionField}
                setOptionsField={setOptionField}
                decimalField={decimalField}
                setDecimalField={setDecimalField}
                integerDecimalField={integerDecimalField}
                setIntegerDecimalField={setIntegerDecimalField}
                extensionField={extensionField}
                setExtensionField={setExtensionField}
                extensionList={extensionList}
                handleDecimalFieldChange={handleDecimalFieldChange}
                isExtensionChecked={isExtensionChecked}
                setIsExtensionChecked={setIsExtensionChecked}
                otherExtensionField={otherExtensionField}
                setOtherExtensionField={setOtherExtensionField}
                isSavedChecked={isSavedChecked}
                setIsSavedChecked={setIsSavedChecked}
                savedIdField={savedIdField}
                savedInputsFields={savedInputsFields}
                selectedSavedInput={selectedSavedInput}
                setSelectedSavedInput={setSelectedSavedInput}
                isLoadingInputName={isLoadingInputName}
                isSavedField={isSavedField}
            />

            <ModalTable
                addTable={addTable}
                saveEditTable={saveEditTable}
                removeTable={removeTable}
                deleteDataColumn={deleteDataColumn}
                tableIndex={tableIndex}
                groupIndex={groupIndex}
                showModalTable={showModalTable}
                setShowModalTable={setShowModalTable}
                toEditTable={toEditTable}
                idTable={idTable}
                nameTable={nameTable}
                setNameTable={setNameTable}
                dataColumnsTable={dataColumnsTable}
                setDataColumnsTable={setDataColumnsTable}
                modalTitle={modalTitle}
                maskField={maskField}
                setMaskField={setMaskField}
                optionsField={optionField}
                setOptionsField={setOptionField}
                extensionList={extensionList}
                setErrorModal={setErrorModal}
                setErrorModalText={setErrorModalText}
            />
        
            <div style={{marginTop: -15}}>
                {
                    groups ?
                        groups.group.map((group: any, index: number) =>
                            group.processId == processId ?
                                <>
                                    <Grid key={index} container spacing={3}>
                                        <Grid item lg={12} className={`d-flex flex-row align-items-center ${groups.group.length > 1 ? 'justify-content-between' : 'justify-content-end'}`}>
                                            <div className='d-flex flex-row align-items-center'>
                                                <Button
                                                    type="button"
                                                    variant="secondary"
                                                    className="mr-3"
                                                    onClick={() => {
                                                        setModalTitle("Criação de campo");
                                                        setGroupIndex(index);
                                                        setSelectedSavedInput("0");
                                                        setShowModalField(true);
                                                        setNameField("");
                                                        setTypeField("Texto");
                                                        setSizeField(50);
                                                        setRowsField(1);
                                                        setColunsField(3);
                                                        setDecimalField(1);
                                                        setIntegerDecimalField(1);
                                                        setExtensionField([]);
                                                        setOtherExtensionField("");
                                                        setIsExtensionChecked(false);
                                                        setRequiredField("n");
                                                        setMaskField("");
                                                        setOptionField([""]);
                                                        setToEditField(false);
                                                        setIsSavedChecked(false);
                                                        setSavedIdField(0);
                                                    }}
                                                >
                                                    <i className="flaticon2-plus"></i>
                                                    <span>
                                                        Campo
                                                    </span>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="secondary"
                                                    className="mr-3"
                                                    onClick={() => {
                                                        setModalTitle("Criação de tabela")
                                                        setGroupIndex(index);
                                                        setShowModalTable(true);
                                                        setNameTable("");
                                                        setDataColumnsTable([{
                                                            id: 1,
                                                            name: "",
                                                            type: "",
                                                            size: 50,
                                                            rows: 1,
                                                            columns: 3,
                                                            mask: "",
                                                            option: [""],
                                                            decimal: 1,
                                                            integerDecimal: 1,
                                                            extensions: [],
                                                            otherExtension: "",
                                                            required: false,
                                                            savedId: 0,
                                                        }]);
                                                        setColumnsTable(12);
                                                        setRowsTable(1);
                                                        setToEditTable(false);
                                                    }}
                                                >
                                                    <i className="flaticon2-plus"></i>
                                                    <span>
                                                        Tabela
                                                    </span>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="secondary"
                                                    className="mr-3"
                                                    onClick={() => {
                                                        setSavedFieldsModal(true);
                                                        setGroupIndex(index);
                                                        setSearchSavedInput("");
                                                        setSelectedSavedInput("0");
                                                        setFilteredSavedInputsFields(savedInputsFields);
                                                    }}
                                                >
                                                    <i className="flaticon2-plus"></i>
                                                    <span>
                                                        Campos salvos
                                                    </span>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div style={{maxHeight: 450, overflowY: "scroll", overflowX: "hidden", marginTop: 10}}>
                                        <div key={`fieldGroup-${index}`} className='d-flex row mb-6' style={{ rowGap: "1rem" }}>
                                            {
                                                group.inputs.map((field: any, indexField: number) =>
                                                    field?
                                                        field.name ?
                                                            <div
                                                                key={`field-${field.id}`}
                                                                className={"col-lg-12"}
                                                                style={indexField ? {marginTop: -7} : {marginTop: 0}}
                                                            >
                                                                <div className="card card-custom shadow-sm border">
                                                                    <div className="card-header" style={{minHeight: 47}}>
                                                                        <h6 className="card-title">Campo</h6>
                                                                        <div className="card-toolbar">
                                                                            <Button
                                                                                type="button"
                                                                                variant="primary"
                                                                                size='sm'
                                                                                onClick={() => {
                                                                                    setModalTitle("Edição de campo");
                                                                                    editField(field);
                                                                                    setGroupIndex(index);
                                                                                }}
                                                                                style={{marginRight: 5}}
                                                                            >
                                                                                <i className="flaticon2-edit p-0"></i>
                                                                            </Button>
                                                                            <Button
                                                                                type="button"
                                                                                variant="danger"
                                                                                size='sm'
                                                                                onClick={() => removeField(index, indexField, field)}
                                                                            >
                                                                                <i className="flaticon2-trash p-0" key={`trash_${indexField}`}></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body py-2">
                                                                        <div className="d-flex flex-column" style={{height: "50px"}}>
                                                                            <li className="d-flex align-items-start py-0">
                                                                                <span className="bullet bullet-vertical h-5px w-15px mr-3 mt-2"></span>
                                                                                <div className='d-flex align-items-start'>
                                                                                    Nome: <b className='ml-1'>{field.name}</b>
                                                                                </div>
                                                                            </li>
                                                                            <li className="d-flex align-items-center py-0">
                                                                                <span className="bullet bullet-vertical h-5px w-15px mr-3"></span>
                                                                                Tipo: <b className='ml-1'>{formatType[field.type]}</b>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <span className="bullet bullet-vertical h-5px w-15px mr-3"></span>
                                                                                Obrigatório: <b className='ml-1'>{field.required ? "Sim" : "Não"}</b>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <></>
                                                        : <></>
                                                )
                                            }
                                        </div>

                                        <div key={`tableGroup-${index}`} className="row" style={{ rowGap: "1rem" }}>
                                            {
                                                group.tables.map((table: any, indexTable: number) =>
                                                    table.name ?
                                                        <div key={`table-${indexTable}`} className={`col-lg-12`}>
                                                            <div className="card card-custom shadow-sm border">
                                                                <div className="card-header" style={{minHeight: 47}}>
                                                                    <h6 className="card-title">Tabela</h6>
                                                                    <div className="card-toolbar">
                                                                        <Button
                                                                            type="button"
                                                                            variant="primary"
                                                                            size='sm'
                                                                            onClick={() => {
                                                                                setModalTitle("Edição de tabela")
                                                                                editTable(table);
                                                                                setGroupIndex(index)
                                                                                setTableIndex(indexTable);
                                                                            }}
                                                                            style={{marginRight: 5}}
                                                                        >
                                                                            <i className="flaticon2-edit p-0"></i>
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            variant="danger"
                                                                            size='sm'
                                                                            onClick={() => removeTable(index, indexTable, table)}
                                                                        >
                                                                            <i className="flaticon2-trash p-0" key={`trash_${indexTable}`}></i>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body py-2">
                                                                    <div className="d-flex flex-column">
                                                                        <li className="d-flex align-items-start py-2">
                                                                            <span className="bullet bullet-vertical h-5px w-15px mr-3 mt-2"></span>
                                                                            <div className='d-flex align-items-start'>
                                                                                Nome Tabela: <b className='ml-1'>{table.name}</b>
                                                                            </div>
                                                                        </li>
                                                                        {table.dataColumns.map((dataColumn: any) => (
                                                                            <li className="d-flex align-items-start py-0">
                                                                                <span className="bullet bullet-vertical bg-primary h-5px w-15px mr-3 mt-2"></span>
                                                                                <div className="d-flex flex-column">
                                                                                    <div className='d-flex align-items-start'>
                                                                                        Coluna: <b className='ml-1'>{dataColumn.name}</b>
                                                                                    </div>
                                                                                    <div className='d-flex align-items-start'>
                                                                                        tipo: <b className='ml-1'>{dataColumn.type}</b>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : <></>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                                : <></>
                        )
                        :<></>
                }
            </div>
        </>
    );
}

export default BpmnModalForm;
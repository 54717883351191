import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, TextField } from '@material-ui/core';
import api from '../../../services/Api';
import { Badge, Button, Spinner } from 'react-bootstrap';
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    AreaChart,
    Area,
} from "recharts";
import {
    AllSolicitationSituations,
    DeadlineSolicitations,
    MonthlySolicitations,
    OpenSolicitationPerProcess,
    SolicitationSituations,
} from "./List-Solicitation";

const formatSituationName: {[ key: string ]: string} = {
    "active": "Abertas",
    "completed": "Concluídas"
}

const formatDeadlineName: {[ key: string]: string} = {
    "late": "Atrasada",
    "on_time": "No prazo"
}

const formatMonthName: {[ key: string ]: string} = {
    "01": "Jan",
    "02": "Fev",
    "03": "Mar",
    "04": "Abr",
    "05": "Mai",
    "06": "Jun",
    "07": "Jul",
    "08": "Ago",
    "09": "Set",
    "10": "Out",
    "11": "Nov",
    "12": "Dez",
}

type DashboardProps = {
    solicitationsStatus: SolicitationSituations[],
    dataCompletedSolicitations: MonthlySolicitations[],
    dataIncompletedSolicitations: MonthlySolicitations[],
    dataAllSolicitationStatus: AllSolicitationSituations[],
    dataDeadlinesSolicitations: DeadlineSolicitations[],
    dataOpenSolicitationsPerProcess: OpenSolicitationPerProcess[],
}

const Dashboard = (
    {
        solicitationsStatus,
        dataCompletedSolicitations,
        dataIncompletedSolicitations,
        dataAllSolicitationStatus,
        dataDeadlinesSolicitations,
        dataOpenSolicitationsPerProcess,
    }:
        DashboardProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [username, setUsername] = useState<string>("");

    const dataTarefasAbertas = [
        {
            name: "Recrutamento e seleção Principal",
            quantity: 9,
        },
        {
            name: "Organograma",
            quantity: 15,
        },
        {
            name: "RECRUTAMENTO & SELEÇÃO DE PESSOAS TESTE 0001",
            quantity: 2,
        },
        {
            name: "Contratação",
            quantity: 20,
        },
        {
            name: "Logs",
            quantity: 17,
        },
    ]

    useEffect(() => {
        const fetch = async() => {
            try {
                await setSolicitations();
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, []);

    const setSolicitations = async() => {
        setIsLoading(true);
        let start = startDate ? startDate : "all";
        let end = endDate ? endDate : "all";
        let actRes = await api.get(`/bpm/solicitations/dashboard/start/${start}/end/${end}`);
        setIsLoading(false);
    }

    const applyFilter = async() => {
        if (startDate || endDate || username) {
            await setSolicitations();
        }
    }

    function formatMonth(tickItem: string) {
        return formatMonthName[tickItem];
    }

    function formatDeadline(tickItem: string) {
        return formatDeadlineName[tickItem];
    }

    function formatSituation(tickItem: string) {
        return formatSituationName[tickItem];
    }

    const CustomTooltipMonth = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
            <div className="bg-white p-2" style={{ border: "1px solid #ccc" }}>
                <h6>{formatMonthName[label]}</h6>
                <p>{payload[0].value} solicitações</p>
            </div>
            );
        }
        
        return null;
    };

    const CustomTooltipAllStatus = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
            <div className="bg-white p-2" style={{ border: "1px solid #ccc" }}>
                <h6>{formatSituationName[label]}</h6>
                <p>{payload[0].value} de {payload[1].value} solicitações totais</p>
            </div>
            );
        }
        
        return null;
    };

    const CustomTooltipDeadlines = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
            <div className="bg-white p-2" style={{ border: "1px solid #ccc" }}>
                <h6>{formatDeadlineName[label]}</h6>
                <p>{payload[0].value} de {payload[1].value} solicitações totais</p>
            </div>
            );
        }
        
        return null;
    };

    const CustomTooltipOpenPerProcess = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
            <div className="bg-white p-2" style={{ border: "1px solid #ccc" }}>
                <h6>{label}</h6>
                <p>{payload[0].value} solicitações</p>
            </div>
            );
        }
        
        return null;
    };

    return (
        <>
            {/* <div style={{...styles.header, border:"none", marginBottom: 20, display: "relative"}}>
                <div style={{width: "100%", display: "inline-flex", position: "relative", zIndex: 49,}}>
                    <div style={{width: "40%"}}>
                        <TextField
                            label={"Data inicial"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{width: "49%", marginRight: "1%"}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label={"Prazo"}
                            size="small"
                            margin={"normal"}
                            variant={"outlined"}
                            type={"date"}
                            placeholder={undefined}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{width: "49%", marginRight: "1&"}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
                <div style={styles.filterButtons}>
                    {
                        startDate || endDate ?
                            <Button
                                onClick={() => clearFilter()}
                                variant={"danger"}
                                style={{marginRight: 5}}
                            >
                                <i className="flaticon2-cross p-0"/>
                            </Button>
                            : <></>
                    }
                    <Button
                        onClick={() => applyFilter()}
                    >
                        <i className="flaticon-search p-0"/>
                    </Button>
                </div>
            </div> */}

            <Grid container spacing={3}>
                {
                    solicitationsStatus && solicitationsStatus.length > 0
                    ?
                        solicitationsStatus.map((solicitationStatus: SolicitationSituations, index: number) => (
                            <Grid key={index} item lg={6}>
                                <Box className="card" p={2}>
                                    <h6 className="mb-4">{formatSituationName[solicitationStatus.status]}</h6>
                                    <h2>{solicitationStatus.quantity}</h2>
                                </Box>
                            </Grid>
                        ))
                    :
                        <Grid item lg={12}>
                            <Box className="card text-center" p={3}>
                                <h6 className="m-0">Não há solicitações nesse período especificado!</h6>
                            </Box>
                        </Grid>
                }
            </Grid>

            <div className="d-flex flex-column col-12 text-left mt-6" style={styles.content}>
                <h4 className="mb-6">Tarefas abertas por processo</h4>
                <BarChart
                    width={1090}
                    height={420}
                    data={dataOpenSolicitationsPerProcess}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="processName" />
                    <YAxis />
                    <Tooltip content={<CustomTooltipOpenPerProcess />} />
                    <Legend />
                    <Bar name="Quantidade" dataKey="quantity" fill="#82ca9d" />
                </BarChart>
            </div>

            <div className="d-flex flex-column col-12 text-left mt-6" style={styles.content}>
                <h4 className="mb-6">Status geral das solicitações</h4>
                <LineChart
                    width={1090}
                    height={420}
                    data={dataAllSolicitationStatus}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="status" tickFormatter={formatSituation} />
                    <YAxis />
                    <Tooltip content={<CustomTooltipAllStatus />} />
                    <Legend />
                    <Line name="Quantidade" type="monotone" dataKey="quantity" stroke="#8884d8" />
                    <Line name="Total" type="monotone" dataKey="total" stroke="#82ca9d" />
                </LineChart>
            </div>

            <div className="d-flex flex-column col-6 text-left mt-6" style={{...styles.content, float: "left" as "left", width: "49%"}}>
                <h4 className="mb-6">Solicitações mensais completas</h4>
                <BarChart
                    width={500}
                    height={300}
                    data={dataCompletedSolicitations}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={20}
                >
                    <XAxis dataKey="month" tickFormatter={formatMonth} padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip content={<CustomTooltipMonth />} />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar name="Quantidade" dataKey="quantity" fill="#555" background={{ fill: '#eee' }} />
                </BarChart>
            </div>

            <div className="d-flex flex-column col-6 text-left mt-6" style={{...styles.content, width: "49%"}}>
                <h4 className="mb-6">Solicitações mensais não completas</h4>
                <BarChart
                    width={500}
                    height={300}
                    data={dataIncompletedSolicitations}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barSize={20}
                >
                    <XAxis dataKey="month" tickFormatter={formatMonth} padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip content={<CustomTooltipMonth />} />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar name="Quantidade" dataKey="quantity" fill="#555" background={{ fill: '#eee' }} />
                </BarChart>
            </div>

            <div className="d-flex flex-column col-12 text-left mt-6" style={styles.content}>
                <h4 className="mb-6">Solicitações do mês no prazo e em atraso</h4>
                <AreaChart
                    width={1090}
                    height={420}
                    data={dataDeadlinesSolicitations}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="situation" tickFormatter={formatDeadline} />
                    <YAxis />
                    <Tooltip content={<CustomTooltipDeadlines /> } />
                    <Area name="Quantidade" type="monotone" dataKey="quantity" stroke="#8884d8" fill="#8884d8" />
                    <Area name="Total" type="monotone" dataKey="total" stroke="#8884d8" fill="#FFF" />
                </AreaChart>
            </div>
        </>
    );
}

const styles = {
    content: {
        // overflow: "scroll",
        width: "100%",
        textAlign: "center" as "center",
    },
    header: {
        width: "100%",
        border: `1px solid #DCDCDC`,
        borderRadius: 6,
        marginTop: -20,
        marginBottom: 40,
    },
    filterButtons: {
        float: "right" as "right",
        marginTop: -45,
        position: "relative" as "relative",
        zIndex: 50,
    },
}

export default Dashboard;
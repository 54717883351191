import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Field, ModalField } from './ModalField';
import { Table, DataColumn, ModalTable } from './ModalTable';
import api from '../../../services/Api';
import { useHistory, useParams } from 'react-router';
import { useStyles } from '../../../hooks/styles';
import ModalError from '../../../components/ModalError';
import ModalSuccess from '../../../components/ModalSuccess';

type param = {
    formId: string,
}

const formatType: {[key: string]: string} = {
    "Texto": "Texto",
    "Inteiro": "Número inteiro",
    "Decimal": "Número decimal",
    "OPCOES": "Opções",
    "Mascara": "Máscara",
    "Data": "Data",
    "Anexo": "Anexo",
    "Caixa_verificacao": "Caixa de verificação",
}

export function NewModelForm() {

    const { push: pushHistory, location: { pathname } } = useHistory();
    const { formId } = useParams<param>();
    const { register, watch, handleSubmit } = useForm();
    
    const [name, setName] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [nameInvalid, setNameInvalid] = useState(false);
    const [groupsInvalid, setGroupsInvalid] = useState(false);

    const [showModalField, setShowModalField] = useState<boolean>(false);
    const [showModalTable, setShowModalTable] = useState<boolean>(false);
    const [toEditField, setToEditField] = useState<boolean>(false);
    const [toEditTable, setToEditTable] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    
    const [groupIndex, setGroupIndex] = useState<number>(0);
    const [tableIndex, setTableIndex] = useState<number>(0);

    const [fieldCounter, setFieldCounter] = useState<number>(1);
    const [idField, setIdField] = useState<number>(1);
    const [nameField, setNameField] = useState<string>("");
    const [typeField, setTypeField] = useState<string>("Texto");
    const [sizeField, setSizeField] = useState<number>(50);
    const [rowsField, setRowsField] = useState<number>(1);
    const [colunsField, setColunsField] = useState<number>(3);
    const [requiredField, setRequiredField] = useState<string>("y");
    const [maskField, setMaskField] = useState<string>("");
    const [optionField, setOptionField] = useState<string[]>([""]);
    const [decimalField, setDecimalField] = useState<number>(1);
    const [integerDecimalField, setIntegerDecimalField] = useState<number>(1);
    const [extensionField, setExtensionField] = useState<any>([]);
    const [otherExtensionField, setOtherExtensionField] = useState<string>("");
    const [savedIdField, setSavedIdField] = useState<number>(0);
    const extensionList = [
        '.jpg', '.jpeg', '.png', '.pdf',
    ];

    const [isLoadingInputName, setIsLoadingInputName] = useState<boolean>(false);

    const [savedInputsFields, setSavedInputsFields] = useState<any>([]);
    const [selectedSavedInput, setSelectedSavedInput] = useState<any>([]);

    const [isExtensionChecked, setIsExtensionChecked] = useState<boolean>(false);
    const [isSavedChecked, setIsSavedChecked] = useState<boolean>(false);
    
    const [removeGroups, setRemoveGroups] = useState<number[]>([]);
    const [removeFields, setRemoveFields] = useState<number[]>([]);
    const [removeTables, setRemoveTables] = useState<number[]>([]);

    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorModalText, setErrorModalText] = useState<string>("");
    const [successModal, setSuccessModal] = useState<boolean>(false);

    const [idTable, setIdTable] = useState<number>(1);
    const [nameTable, setNameTable] = useState<string>("");
    const [dataColumnsTable, setDataColumnsTable] = useState<DataColumn[]>([{
        id: 0,
        name: "",
        type: "",
        size: 50,
        rows: 1,
        columns: 3,
        mask: "",
        option: [""],
        decimal: 1,
        integerDecimal: 1,
        extensions: [],
        otherExtension: "",
        required: false,
        savedId: 0,
    }]);
    const [columnsTable, setColumnsTable] = useState<number>(12);
    const [rowsTable, setRowsTable] = useState<number>(1);
    
    const [groups, setGroups] = useState({ group: [
        {
            name: "",
            index: 0,
            savedId: 0,
            inputs: [
                {
                    id: 0,
                    name: "",
                    type: "",
                    size: 0,
                    rows: 0,
                    columns: 0,
                    mask: "",
                    option: [""],
                    decimal: 0,
                    integerDecimal: 0,
                    extensions: [],
                    otherExtension: "",
                    required: false,
                    savedId: 0,
                    saveField: false,
                    savedField: false,
                }
            ],
            tables: [
                {
                    id: 0,
                    name: "",
                    dataColumns: [
                        {
                            id: 0,
                            name: "",
                            type: "",
                            size: 50,
                            rows: 1,
                            columns: 3,
                            mask: "",
                            option: [""],
                            extensions: [],
                            otherExtension: "",
                            decimal: 1,
                            integerDecimal: 1,
                            required: false,
                            savedId: 0
                        }
                    ],
                    columns: 0,
                    rows: 0,
                    savedId: 0,
                }
            ],
        }
    ]});

    const classes = useStyles();

    useEffect(() => {
        groups.group[0].inputs.shift();
        groups.group[0].tables.shift();

        const fetch = async() => {
            if (parseInt(formId)) {
                let resp = await api.get(`/bpm/form/display/${formId}`);
                let data = resp.data;
                console.log(data)
                setName(data.form[0].name);
                let groupData = data.groups;
                let fieldData = data.fields;
                let tableData = data.tables;
                let tableFieldData = data.tableFields;
                
                let gCount = groupData.length;
                let fCount = fieldData.length;
                let tCount = tableData.length;
                let tfCount = tableFieldData.length;
                let groupArr: any = {group: []};
                let fCounter = fieldCounter;

                for (let i = 0; i < gCount; i++) {
                    let group = groupData[i];
                    groupArr.group.push({
                        name: group.name,
                        index: i,
                        savedId: group.id,
                        inputs: [],
                        tables: [],
                    });
                }

                for (let i = 0; i < gCount; i++) {
                    let groupArrI =  groupArr.group[i];
                    let group = groupData[i];

                    let input = [];
                    for (let j = 0; j < fCount; j++) {
                        let field = fieldData[j];
                        if (field.groupFormId == group.id) {
                            let option = field.options.split("*|||*");
                            let required = field.isRequired == 'y' ? true : false;
                            let extensions: any = field.extensions ? field.extensions.split(',') : [];
                            input.push({
                                id: fCounter,
                                name: field.textLabel,
                                type: field.type,
                                size: field.length,
                                rows: field.rows,
                                columns: field.lengthColumn,
                                mask: field.mask,
                                option,
                                extensions,
                                otherExtension: field.otherExtension,
                                decimal: field.decimal,
                                integerDecimal: field.integerDecimal,
                                required,
                                savedId: field.id,
                                saveField: false,
                            })
                            fCounter++;
                        }
                    }
                    groupArrI.inputs = input;

                    let tables = [];
                    let stCounter = 0;
                    for (let k = 0; k < tCount; k++) {
                        let table = tableData[k];
                        if (table.groupFormId == group.id) {
                            tables.push({
                                id: stCounter,
                                name: table.name,
                                dataColumns: [],
                                columns: 0,
                                rows: table.countRows,
                                savedId: table.id,
                            });

                            let dataColumns: any = [];
                            let dColumnsCounter = 0;
                            for (let l = 0; l < tfCount; l++) {
                                let tField = tableFieldData[l];
                                if (tField.TableFieldBpmId == table.id) {
                                    let option = tField.options.split("*|||*");
                                    let required = tField.isRequired == 'y' ? true : false;
                                    let extensions = tField.extensions ? tField.extensions.split(',') : [];
                                    dataColumns.push({
                                        id: dColumnsCounter,
                                        name: tField.textLabel,
                                        type: tField.type,
                                        size: tField.length,
                                        rows: tField.rows,
                                        columns: tField.lengthColumn,
                                        mask: tField.mask,
                                        option,
                                        extensions,
                                        otherExtension: tField.otherExtension,
                                        decimal: tField.decimal,
                                        integerDecimal: tField.integerDecimal,
                                        required,
                                        savedId: tField.id,
                                    });
                                    dColumnsCounter++;
                                }
                            }
                            tables[stCounter].dataColumns = dataColumns;
                            stCounter++;
                        }
                    }
                    groupArrI.tables = tables;
                }
                setFieldCounter(fCounter);
                console.log(groupArr);
                setGroups(groupArr);
            }
        }
        fetch().then();
    }, [formId]);

    const addGroup = useCallback(() => {
        var groupArr = groups.group;
        var lastIndex = groups.group.length ? groups.group.length : 0;
        groupArr.push({
            name: "",
            index: lastIndex,
            savedId: 0,
            inputs: [],
            tables: []
        });
        setGroups({group: groupArr});
    }, [groups]);

    const deleteGroup = async(index: number) => {
        var groupArr = groups.group;
        console.log(index, groupArr, groupArr[index].savedId, groupArr[index]);
        if (groupArr[index].savedId) {
            let rem = removeGroups;
            rem.push(groupArr[index].savedId);
            setRemoveGroups(rem);
        }
        groupArr.splice(index, 1);
        setGroups({ group: groupArr });
    };

    const changeNameGroup = useCallback((index: number, value: string) => {
        var groupArr = groups.group;
        groupArr[index].name = value;
        setGroups({ group: groupArr });
    }, [groups]);

    const changeIndexGroup = (index: number, direction: "down" | "up") => {
        if (!(index == 0 && direction == "up") && !(groups.group.length == (index + 1) && direction == "down")) {
            var groupArr = groups.group;
            if (direction == "up") {
                var position = index - 1;
                var group    = groupArr[index];
        
                for (var i = index; i >= position; i--) {
                    groupArr[i] = groupArr[i - 1];
                }
                groupArr[position] = group; 
                
                setGroups({ group: groupArr });
            } else {
                var position = index + 1;
                var group    = groupArr[index];
        
                for (var i = index; i <= position; i++) {
                    groupArr[i] = groupArr[i + 1];
                }
                groupArr[position] = group; 
                
                setGroups({ group: groupArr });
            }
        }
    };

    const changeIndexField = (indexGroup: number, indexField: number, direction: "right" | "left") => {
        if (!(indexField === 0 && direction === "left") && !(indexField === groups.group[indexGroup].inputs.length - 1 && direction === "right")) {
            var inputArr = groups.group[indexGroup].inputs;
            
            if (direction === "left") {
                var position = indexField - 1;
                var input = groups.group[indexGroup].inputs[indexField];

                for (var i = indexField; i >= position; i--) {
                    inputArr[i] = inputArr[i - 1];
                }
                inputArr[position] = input;

                const newGroups = {...groups}
                setGroups(newGroups);
                console.log(newGroups.group[indexGroup].inputs);
            } else {
                var position = indexField + 1;
                var input = groups.group[indexGroup].inputs[indexField];
                
                for (var i = indexField; i <= position; i++) {
                    inputArr[i] = inputArr[i + 1]
                }
                inputArr[position] = input;

                const newGroups = {...groups}
                setGroups(newGroups);
                console.log(newGroups.group[indexGroup].inputs);
            }
        }
    };

    const addField = useCallback((index: number) => {
        if (!nameField) {
            setErrorModal(true);
            setErrorModalText("Preencha o nome do campo!");
            return;
        }

        var groupArr = groups.group;
        groupArr[index].inputs.push({
            id: fieldCounter,
            name: nameField,
            type: typeField,
            size: sizeField,
            rows: rowsField,
            columns: colunsField,
            mask: maskField,
            option: optionField,
            decimal: decimalField,
            integerDecimal: integerDecimalField,
            extensions: extensionField,
            otherExtension: otherExtensionField,
            required: requiredField == "y" ? true : false,
            savedId: 0,
            saveField: false,
            savedField: false,
        });
        let count = fieldCounter + 1;
        setFieldCounter(count);
        setNameField("");
        setTypeField("Texto");
        setSizeField(50);
        setRowsField(1);
        setColunsField(3);
        setRequiredField("y");
        setMaskField("");
        setOptionField([""]);
        setDecimalField(1);
        setIntegerDecimalField(1);
        setExtensionField([]);
        setOtherExtensionField("");
        setShowModalField(false);
        setToEditField(false);
    }, [
        nameField,
        typeField,
        sizeField,
        rowsField,
        colunsField,
        requiredField,
        maskField,
        decimalField,
        integerDecimalField,
        optionField,
        extensionField,
        otherExtensionField,
        fieldCounter,
    ]);

    const addTable = useCallback((index: number) => {
        if (!nameTable) {
            setErrorModal(true);
            setErrorModalText("Preencha o nome da tabela!");
            return;
        }

        var groupArr = groups.group;
        groupArr[index].tables.push({
            id: groupArr[index].tables.length + 1,
            name: nameTable,
            dataColumns: dataColumnsTable,
            columns: columnsTable,
            rows: rowsTable,
            savedId: 0,
        });

        setNameTable("");
        setDataColumnsTable([{
            id: 0,
            name: "",
            type: "",
            size: 50,
            rows: 1,
            columns: 3,
            mask: "",
            option: [""],
            decimal: 1,
            integerDecimal: 1,
            extensions: [],
            otherExtension: "",
            required: false,
            savedId: 0,
        }]);
        setColumnsTable(12);
        setRowsTable(1);
        setShowModalTable(false);
    }, [nameTable, dataColumnsTable, columnsTable, rowsTable]);

    const removeField = useCallback((indexGroup: number, idFieldOrTable: number) => {
        var groupArr = groups.group;
        groupArr[indexGroup].inputs = groupArr[indexGroup].inputs.filter((input) => {return input.id !== idFieldOrTable});

        console.log(groupArr, idFieldOrTable);
        setGroups({ group: groupArr});    
        
        setNameField("");
        setTypeField("Texto");
        setSizeField(50);
        setRowsField(1);
        setColunsField(3);
        setRequiredField("y");
        setMaskField("");
        setOptionField([""]);
        setDecimalField(1);
        setIntegerDecimalField(1);
        setExtensionField([]);
        setOtherExtensionField("");
        setShowModalField(false);
        setToEditField(false);
    }, [
        nameField,
        typeField,
        sizeField,
        rowsField,
        colunsField,
        requiredField,
        maskField,
        decimalField,
        integerDecimalField,
        optionField,
        extensionField,
        otherExtensionField,
    ]);

    const removeTable = useCallback((indexGroup: number, idTable: number) => {
        var groupArr = groups.group;
        groupArr[indexGroup].tables = groupArr[indexGroup].tables.filter((table) => {return table.id !== idTable});

        console.log(groupArr, idTable);
        setGroups({ group: groupArr});    
        
        setNameTable("");
        setDataColumnsTable([{
            id: 0,
            name: "",
            type: "",
            size: 50,
            rows: 1,
            columns: 3,
            mask: "",
            option: [""],
            decimal: 1,
            integerDecimal: 1,
            extensions: [],
            otherExtension: "",
            required: false,
            savedId: 0,
        }]);
        setColumnsTable(12);
        setRowsTable(1);
        setShowModalTable(false);
        setToEditTable(false);
    }, [nameTable, dataColumnsTable, columnsTable, rowsTable]);

    const handleDecimalFieldChange = (e: ChangeEvent<HTMLInputElement> | any) => {
        if (Number(e.target.value) < 0) {
            setDecimalField(1);
            return;
        }

        if (Number(e.target.value) > 5) {
            setDecimalField(5);
            return;
        }

        setDecimalField(Number(e.target.value));
    }

    const deleteDataColumn = (savedId: number) => {
        let rem = removeFields;
        rem.push(savedId);
        setRemoveFields(rem);
    };

    const editField = useCallback((field: Field) => {
        let required = field.required ? "y" : "n";
        let newField = {...field, required: required}; // Cria uma nova cópia do objeto field

        setIdField(newField.id);
        setNameField(newField.name);
        setTypeField(newField.type);
        setSizeField(newField.size);
        setRowsField(newField.rows);
        setColunsField(newField.columns);
        setRequiredField(required);
        setMaskField(newField.mask);
        setOptionField(newField.option);
        setDecimalField(newField.decimal);
        setIntegerDecimalField(newField.integerDecimal)
        setExtensionField(newField.extensions);
        setOtherExtensionField(newField.otherExtension);
        setToEditField(true);
        setShowModalField(true);
    }, []);

    const saveEditField = useCallback((groupIndex: number, fieldIndex: number) => {
        const newGroups = [...groups.group];
        let field = newGroups[groupIndex].inputs.find((input) => input.id === fieldIndex);
        field!.name = nameField;
        field!.type = typeField;
        field!.size = sizeField;
        field!.rows = rowsField;
        field!.columns = colunsField;
        field!.required = requiredField === "y" ? true : false;
        field!.mask = maskField;
        field!.option = optionField;
        field!.decimal = decimalField;
        field!.integerDecimal = integerDecimalField;
        field!.extensions = extensionField;
        field!.otherExtension = otherExtensionField;
        field!.saveField = false;

        setShowModalField(false);
        setToEditField(false);
        setGroups({group: newGroups});
    }, [
        nameField,
        typeField,
        sizeField,
        rowsField,
        colunsField,
        requiredField,
        maskField,
        optionField,
        decimalField,
        integerDecimalField,
        extensionField,
        otherExtensionField,
    ]);

    const editTable = useCallback((table: Table) => {
        console.log(table);

        setIdTable(table.id);
        setNameTable(table.name);
        setDataColumnsTable(table.dataColumns);
        setColumnsTable(table.columns);
        setRowsTable(table.rows);
        setToEditTable(true);
        setShowModalTable(true);
    }, []);

    const saveEditTable = useCallback((groupIndex: number, tableindex: number) => {
        const newGroups = [...groups.group];
        let table = newGroups[groupIndex].tables.find((table) => table.id === tableindex);
        table!.name = nameTable;
        table!.dataColumns = dataColumnsTable;
        table!.columns = columnsTable;
        table!.rows = rowsTable;
        setShowModalTable(false);
        setToEditTable(false);
    }, [nameTable, dataColumnsTable, columnsTable, rowsTable]);

    function inputsVerify() {
        let emptyGroupValidation = true;

        if (!name) {
            setIsSubmit(false);
            setErrorModalText("Preencha o nome do formulário!");
            setErrorModal(true);
            setNameInvalid(true);

            return false;
        }

        if (groups.group.some(grupo => !grupo.name)) {
            setIsSubmit(false);
            setErrorModalText("Preencha todos os nomes dos grupos!");
            setErrorModal(true);
            setGroupsInvalid(true);

            return false;
        }
        
        groups.group.forEach((group) => {
            if(group.inputs.length === 0 && group.tables.length === 0) {
                emptyGroupValidation = false;
                return;
            }
        })

        if(!emptyGroupValidation) {
            setIsSubmit(false);
            setErrorModalText("Todos os grupos devem ter pelo menos um campo ou tabela!");
            setErrorModal(true);

            return false;
        }

        return true;
    }
    
    async function onSubmit() {
        if (!inputsVerify()) return;

        setIsSubmit(true);
        try {
            let obj = {
                formId,
                name,
                groups: JSON.stringify(groups),
                removeFields,
                removeGroups,
            }
    
            let resp: any = await api.post("/bpm/form/save", obj);
            console.log(resp);

        } catch (error) {
            console.log(error);
        }
        setIsSubmit(false);
        setSuccessModal(true);
    }
    
    return (
        <>
            <ModalSuccess
                msgModal={`Formulário ${formId !== "0" ? "atualizado" : "criado"} com sucesso!`}
                showModal={successModal}
                setShowModal={setSuccessModal}
                redirect='/bpm/modelagem-de-formulario'
            />

            <Modal show={errorModal} onHide={() => setErrorModal(false)}>
                <Modal.Header className="d-flex align-items-center">
                    <Modal.Title>
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{errorModalText}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setErrorModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            {/* <ModalField
                addField={addField}
                saveEditField={saveEditField}
                removeField={removeField}
                groupIndex={groupIndex}
                showModalField={showModalField}
                setShowModalField={setShowModalField}
                toEditField={toEditField}
                idField={idField}
                nameField={nameField}
                setNameField={setNameField}
                typeField={typeField}
                setTypeField={setTypeField}
                sizeField={sizeField}
                setSizeField={setSizeField}
                rowsField={rowsField}
                setRowsField={setRowsField}
                colunsField={colunsField}
                setColunsField={setColunsField}
                requiredField={requiredField}
                setRequiredField={setRequiredField}
                modalTitle={modalTitle}
                maskField={maskField}
                setMaskField={setMaskField}
                optionsField={optionField}
                setOptionsField={setOptionField}
                decimalField={decimalField}
                setDecimalField={setDecimalField}
                integerDecimalField={integerDecimalField}
                setIntegerDecimalField={setIntegerDecimalField}
                extensionField={extensionField}
                setExtensionField={setExtensionField}
                extensionList={extensionList}
                handleDecimalFieldChange={handleDecimalFieldChange}
                isExtensionChecked={isExtensionChecked}
                setIsExtensionChecked={setIsExtensionChecked}
                otherExtensionField={otherExtensionField}
                setOtherExtensionField={setOtherExtensionField}
                isSavedChecked={isSavedChecked}
                setIsSavedChecked={setIsSavedChecked}
                savedIdField={savedIdField}
                savedInputsFields={savedInputsFields}
                selectedSavedInput={selectedSavedInput}
                setSelectedSavedInput={setSelectedSavedInput}
                isLoadingInputName={isLoadingInputName}
            /> */}

            <ModalTable
                addTable={addTable}
                saveEditTable={saveEditTable}
                removeTable={removeTable}
                deleteDataColumn={deleteDataColumn}
                tableIndex={tableIndex}
                groupIndex={groupIndex}
                showModalTable={showModalTable}
                setShowModalTable={setShowModalTable}
                toEditTable={toEditTable}
                idTable={idTable}
                nameTable={nameTable}
                setNameTable={setNameTable}
                dataColumnsTable={dataColumnsTable}
                setDataColumnsTable={setDataColumnsTable}
                modalTitle={modalTitle}
                maskField={maskField}
                setMaskField={setMaskField}
                optionsField={optionField}
                setOptionsField={setOptionField}
                extensionList={extensionList}
                setErrorModal={setErrorModal}
                setErrorModalText={setErrorModalText}
            />
        
            <form onSubmit={handleSubmit(onSubmit)} className="card card-body pt-4">
                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            required
                            type="string"
                            label="Nome do Formulário"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setNameInvalid(e.target.value ? false : true);
                            }}
                            className={classes.error}
                            error={nameInvalid}
                        />
                    </Grid>
                    <Grid item lg={8}>
                        <div className="h-100 d-flex justify-content-end align-items-end">
                            <Button
                                type="button"
                                variant="secondary"
                                className="mb-2 mr-3"
                                onClick={() => addGroup()}
                            >
                                <i className="flaticon2-plus"></i>
                                <span>
                                    Grupo
                                </span>
                            </Button>
                            <Button
                                type="button"
                                variant="primary"
                                disabled={isSubmit}
                                className="mb-2"
                                onClick={() => onSubmit()}
                            >
                                {isSubmit ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className="ml-2">
                                        Aguarde...
                                    </span>
                                </> : <>
                                    <span>
                                        Salvar
                                    </span>
                                </>}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <hr />
                {
                    groups.group.map((group, index) => {
                        return (
                            <>
                                <Grid key={index} container spacing={3}>
                                    <Grid item lg={4} className="d-flex flex-row align-items-center">
                                        <TextField
                                            required
                                            type="string"
                                            label="Nome do Grupo"
                                            size="small"
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className={`${classes.error} ${groups.group.length > 1 ? 'ml-3' : ''}`}
                                            value={group.name}
                                            onChange={(e) => changeNameGroup(index, e.target.value)}
                                            error={groupsInvalid}
                                        />
                                    </Grid>

                                    <Grid item lg={8} className={`d-flex flex-row align-items-center ${groups.group.length > 1 ? 'justify-content-between' : 'justify-content-end'}`}>
                                        {
                                            groups.group.length > 1
                                            ?
                                            <div className='d-flex flex-row align-items-center'>
                                                <Tooltip title="Mover para cima" className={index == 0 ? 'd-none' : 'mr-3'}>
                                                    <IconButton
                                                        onClick={() => changeIndexGroup(index, "up")}
                                                    >
                                                        <i className='flaticon2-arrow-up'></i>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Mover para baixo" className={(index + 1) == groups.group.length ? 'd-none' : ''}>
                                                    <IconButton
                                                        onClick={() => changeIndexGroup(index, "down")}
                                                    >
                                                        <i className='flaticon2-arrow-down'></i>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            : <></>
                                        }
                                        <div className='d-flex flex-row align-items-center'>
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                className="mr-3"
                                                onClick={() => {
                                                    setModalTitle("Criação de campo");
                                                    setGroupIndex(index);
                                                    setShowModalField(true);
                                                    setNameField("");
                                                    setTypeField("Texto");
                                                    setSizeField(50);
                                                    setRowsField(1);
                                                    setColunsField(3);
                                                    setDecimalField(1);
                                                    setIntegerDecimalField(1);
                                                    setExtensionField([]);
                                                    setOtherExtensionField("");
                                                    setIsExtensionChecked(false);
                                                    setRequiredField("y");
                                                    setMaskField("");
                                                    setOptionField([""]);
                                                    setToEditField(false);
                                                }}
                                            >
                                                <i className="flaticon2-plus"></i>
                                                <span>
                                                    Campo
                                                </span>
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="secondary"
                                                className="mr-3"
                                                onClick={() => {
                                                    setModalTitle("Criação de tabela")
                                                    setGroupIndex(index);
                                                    setShowModalTable(true);
                                                    setNameTable("");
                                                    setDataColumnsTable([{
                                                        id: 1,
                                                        name: "",
                                                        type: "",
                                                        size: 50,
                                                        rows: 1,
                                                        columns: 3,
                                                        mask: "",
                                                        option: [""],
                                                        decimal: 1,
                                                        integerDecimal: 1,
                                                        extensions: [],
                                                        otherExtension: "",
                                                        required: false,
                                                        savedId: 0,
                                                    }]);
                                                    setColumnsTable(12);
                                                    setRowsTable(1);
                                                    setToEditTable(false);
                                                }}
                                            >
                                                <i className="flaticon2-plus"></i>
                                                <span>
                                                    Tabela
                                                </span>
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="danger"
                                                title={"Excluir"}
                                                onClick={() => deleteGroup(index)}
                                            >
                                                <i className="flaticon2-trash p-0" key={`sdakhjsgdiuasgdiua${index}`}></i>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                
                                <div key={`fieldGroup-${index}`} className='d-flex row mb-3' style={{ rowGap: "1rem" }}>
                                    {
                                        group.inputs.map((field, indexField) =>
                                            field?
                                                field.name ?
                                                    <div key={`field-${indexField}`} className={field.columns > 12 ? "col-lg-12" : `col-lg-${field.columns}`}>
                                                        <div className="card card-custom shadow-sm border">
                                                            <div className="card-header">
                                                                <h4 className="card-title">Campo</h4>
                                                                <div className="card-toolbar">
                                                                    <Button
                                                                        type="button"
                                                                        variant="primary"
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            setModalTitle("Edição de campo");
                                                                            editField(field);
                                                                            setGroupIndex(index);
                                                                        }}
                                                                    >
                                                                        <i className="flaticon2-edit p-0"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-2">
                                                                <div className="d-flex flex-column" style={{height: "182px"}}>
                                                                    <li className="d-flex align-items-start py-2">
                                                                        <span className="bullet bullet-vertical h-5px w-15px mr-3 mt-2"></span>
                                                                        <div className='d-flex align-items-start'>
                                                                            Nome: <b className='ml-1'>{field.name}</b>
                                                                        </div>
                                                                    </li>
                                                                    <li className="d-flex align-items-center py-2">
                                                                        <span className="bullet bullet-vertical h-5px w-15px mr-3"></span>
                                                                        Tipo: <b className='ml-1'>{formatType[field.type]}</b>
                                                                    </li>
                                                                    <li className="d-flex align-items-center py-2">
                                                                        <span className="bullet bullet-vertical h-5px w-15px mr-3"></span>
                                                                        Colunas: <b className='ml-1'>{field.columns}</b>
                                                                    </li>
                                                                    <li className="d-flex align-items-center py-2">
                                                                        <span className="bullet bullet-vertical h-5px w-15px mr-3"></span>
                                                                        Obrigatório: <b className='ml-1'>{field.required ? "Sim" : "Não"}</b>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer py-2">
                                                                <div className="d-flex flex-row align-items-center justify-content-end">
                                                                    <Tooltip title="Mover para esquerda">
                                                                        <IconButton
                                                                            onClick={() => changeIndexField(index, indexField, "left")}
                                                                            className={"p-1 " + (indexField === 0 ? "d-none" : "")}
                                                                        >
                                                                            <i className='flaticon2-left-arrow'></i>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Mover para direita">
                                                                        <IconButton
                                                                            onClick={() => changeIndexField(index, indexField, "right")}
                                                                            className={"p-1 " + (indexField === group.inputs.length - 1 ? "d-none" : "")}
                                                                        >
                                                                            <i className='flaticon2-right-arrow'></i>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <></>
                                                : <></>
                                        )
                                    }
                                </div>

                                <div key={`tableGroup-${index}`} className="row" style={{ rowGap: "1rem" }}>
                                    {
                                        group.tables.map((table, indexTable) => {
                                            if(table.name) {
                                                return (
                                                    <div key={`table-${indexTable}`} className={`col-lg-12`}>
                                                        <div className="card card-custom shadow-sm border">
                                                            <div className="card-header">
                                                                <h4 className="card-title">Tabela</h4>
                                                                <div className="card-toolbar">
                                                                    <Button
                                                                        type="button"
                                                                        variant="primary"
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            setModalTitle("Edição de tabela")
                                                                            editTable(table);
                                                                            setGroupIndex(index)
                                                                            setTableIndex(indexTable);
                                                                        }}
                                                                    >
                                                                        <i className="flaticon2-edit p-0"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-2">
                                                                <div className="d-flex flex-column">
                                                                    <li className="d-flex align-items-start py-2">
                                                                        <span className="bullet bullet-vertical h-5px w-15px mr-3 mt-2"></span>
                                                                        <div className='d-flex align-items-start'>
                                                                            Nome Tabela: <b className='ml-1'>{table.name}</b>
                                                                        </div>
                                                                    </li>
                                                                    {table.dataColumns.map((dataColumn) => (
                                                                        <li className="d-flex align-items-start py-2">
                                                                            <span className="bullet bullet-vertical bg-primary h-5px w-15px mr-3 mt-2"></span>
                                                                            <div className="d-flex flex-column">
                                                                                <div className='d-flex align-items-start'>
                                                                                    Coluna: <b className='ml-1'>{dataColumn.name}</b>
                                                                                </div>
                                                                                <div className='d-flex align-items-start'>
                                                                                    tipo: <b className='ml-1'>{dataColumn.type}</b>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </>
                        )
                    })
                }
            </form>
        </>
    );
}